import {registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule} from '@angular/core';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {Configuration} from '@kisc/coid-frontend-api';
import {
    AppConfigService,
    BusinessErrorCode,
    customBreakPoints,
    PosthogService,
    SentryInterceptor,
    SentryService,
    TracingInterceptor,
    TracingService,
} from '@kisc/libs/commons';
import {BREAKPOINT, BreakPoint} from '@ngbracket/ngx-layout';
import {TranslateCompiler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/kk';
import 'dayjs/locale/ru';
import customParserFormat from 'dayjs/plugin/customParseFormat';
import {HIGHLIGHT_OPTIONS} from 'ngx-highlightjs';
import {provideNgxMask} from 'ngx-mask';
import {TranslateMessageFormatCompiler} from 'ngx-translate-messageformat-compiler';
import {NgxWebstorageModule} from 'ngx-webstorage';
import versionConfig from '../assets/config/version.json';
import {appRoutes} from './app-routing.module';
import {AuthHolderService} from './domain/auth-holder.service';
import {AppErrorHandler} from './infra/app-error-handler.service';
import {AppComponent} from './root/app.component';

dayjs.extend(customParserFormat);
dayjs.locale('ru');
registerLocaleData(localeRu, 'ru');

const DESKTOP_TO_MOBILE_BREAKPOINT = 1028;

const apiConfigurationFactory = (authHolderService: AuthHolderService): Configuration => new Configuration({
    basePath: '',
    credentials: {
        AuthToken: () => authHolderService.authToken,
        RevokeESignAuth: () => authHolderService.revokeToken,
    },
});

export function translationLoaderFactory(client: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(client, '/assets/i18n/', '.json');
}

export function breakPointsProviderFactory(): BreakPoint[] {
    return customBreakPoints(DESKTOP_TO_MOBILE_BREAKPOINT);
}

export function initApp(
    appConfigService: AppConfigService,
    posthogService: PosthogService,
    authHolderService: AuthHolderService,
    tracingService: TracingService,
    sentryService: SentryService,
): () => Promise<any> {
    return () => appConfigService.init({...versionConfig, enableBackendConfig: true})
        .then(() => tracingService.init(span => {
            span.setAttribute('userId', authHolderService.userData?.userId);
            span.setAttribute('role', authHolderService.userData?.role);
            span.setAttribute('iin', authHolderService.userData?.iinEncrypted);
        }))
        .then(() => posthogService.init(() => ({
                version: AppConfigService.appConfig.frontendVersion,
                user_id: authHolderService.userData?.userId,
                role: authHolderService.userData?.role,
                traceId: tracingService.getTraceId(),
            }),
        ))
        .then(() => sentryService.init({
            tagsFactory: () => ({
                'userId': authHolderService.userData?.userId,
                'role': authHolderService.userData?.role,
                'iin': authHolderService.userData?.iinEncrypted,
                'openTelemetryTraceId': tracingService.getTraceId(),
            }),
            mutedEventsByTags: [
                [{'errorCode': BusinessErrorCode.DOCUMENT_NOT_FOUND}],
                [{'errorCode': BusinessErrorCode.IIN_ATTACHED_TO_ANOTHER_ORGANIZATION}, {'httpStatus': '409'}],
                [{'errorCode': BusinessErrorCode.IIN_ALREADY_EXISTS}, {'httpStatus': '409'}],
            ],
            groupsByTags: {
                'network': [
                    [{'httpStatusText': 'Unknown Error'}, {'httpStatus': '0'}],
                ],
            },
        }));
}

export const httpInterceptorProviders = [
    {provide: HTTP_INTERCEPTORS, useClass: TracingInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: SentryInterceptor, multi: true},
];

@NgModule({
    declarations: [],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {provide: TranslateLoader, useFactory: translationLoaderFactory, deps: [HttpClient]},
            compiler: {
                provide: TranslateCompiler,
                useClass: TranslateMessageFormatCompiler,
            },
        }),
        NgxWebstorageModule.forRoot(),
        RouterModule.forRoot(appRoutes),
        MatNativeDateModule,
        HttpClientModule,
        MatDialogModule,
    ],
    providers: [
        {provide: BREAKPOINT, useFactory: breakPointsProviderFactory, multi: true},
        {
            provide: APP_INITIALIZER,
            useFactory: initApp,
            deps: [AppConfigService, PosthogService, AuthHolderService, TracingService, SentryService],
            multi: true,
        },
        {provide: ErrorHandler, useClass: AppErrorHandler},
        provideNgxMask(),
        {provide: Configuration, useFactory: apiConfigurationFactory, deps: [AuthHolderService]},
        {
            provide: HIGHLIGHT_OPTIONS,
            useValue: {
                coreLibraryLoader: () => import('highlight.js/lib/core'),
                languages: {
                    json: () => import('highlight.js/lib/languages/json'),
                },
            },
        },
        {provide: MAT_DATE_LOCALE, useValue: 'ru'},
        httpInterceptorProviders,
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}

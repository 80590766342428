import {Routes} from '@angular/router';
import {AppVersionComponent, VERSION_PATH} from '@kisc/libs/ui-kit';
import {Path} from './path';

export const appRoutes: Routes = [
    {path: VERSION_PATH, component: AppVersionComponent},
    {
        path: Path.API_HUB,
        loadChildren: () => import('./api-hub-module/api-hub.module').then(m => m.ApiHubModule),
    },
    {
        path: Path.ROOT,
        loadChildren: () => import('./main-module/main.module').then(m => m.MainModule),
    },
];
